<template>
    <custom-table
        :title="$t('pages.ecommerce.orderManagement.changeUsageMulti.title')"
        :subTitle="$t('pages.ecommerce.orderManagement.changeUsageMulti.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        filterComponentName="EcommerceOrderChangeUsageMultiFilter"
        @action="handleClickAction"
        @changeTable="handleTableChange">
        <template v-slot:order="{ row: record }">
            {{ record.payload.order_id ?? $t('pages.ecommerce.orderManagement.changeUsageMulti.allOrders') }}
        </template>
        <template v-slot:justWithoutPackage="{ row: record }">
            <span :class="record.payload.just_without_package ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.payload.just_without_package ? $t('common.yes') : $t('common.no') }}</span>
        </template>
        <template v-slot:startProcessingNow="{ row: record }">
            <span :class="record.payload.start_processing_now ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.payload.start_processing_now ? $t('common.yes') : $t('common.no') }}</span>
        </template>
        <template v-slot:state="{ row: record }">
            <span :class="status[record.status_id].badge" class="badge">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.status.' + status[record.status_id].code) }}</span>
        </template>
        <template v-slot:created_at="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a @click="showDetail(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/general/gen004.svg" />
                    </span>
                </a>
                <el-popconfirm v-if="record.status_id == 0" :title="$t('messages.sureCancel')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/arrows/arr011.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>

    <div class="modal fade" id="kt_modal_detail" ref="detailModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_reservation_detail_header">
                    <h2 class="fw-bolder">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.detail.title') }}</h2>
                    <div id="kt_modal_reservation_detail_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_reservation_detail_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                       <template v-if="Object.keys(detail.product).length">
                           <h3 class="mb-5">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.detail.productTitle') }}</h3>

                           <div class="fv-row mb-3">
                               <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.detail.cols.product') }}:</label>
                               <span class="text-gray-500 fw-bold fs-6">{{ detail.product.translate.title + ' (' + detail.product.group.name + ')' }}</span>
                           </div>
                           <div class="fv-row mb-3">
                               <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.detail.cols.usageMinDate') }}:</label>
                               <span class="text-gray-500 fw-bold fs-6">{{ detail.payload.usage_min_date ? $moment(detail.payload.usage_min_date).format("DD.MM.YYYY - HH:mm:ss") : "-"}}</span>
                           </div>
                           <div class="fv-row mb-3">
                               <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.detail.cols.usageMaxDate') }}:</label>
                               <span class="text-gray-500 fw-bold fs-6">{{ detail.payload.usage_max_date ? $moment(detail.payload.usage_max_date).format("DD.MM.YYYY - HH:mm:ss") : "-"}}</span>
                           </div>
                           <div class="fv-row mb-3">
                               <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.detail.cols.justWithoutPackage') }}:</label>
                               <span class="text-gray-500 fw-bold fs-6">{{ $t('common.' + (detail.payload.just_without_package ? 'yes': 'no')) }}</span>
                           </div>
                           <div class="fv-row mb-3">
                               <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.detail.cols.justWithoutUsage') }}:</label>
                               <span class="text-gray-500 fw-bold fs-6">{{ $t('common.' + (detail.payload.just_without_usage ? 'yes': 'no')) }}</span>
                           </div>
                           <div class="fv-row mb-3" v-if="detail.payload.target_order_statuses_id && detail.payload.target_order_statuses_id.length">
                               <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.detail.cols.order.status') }}:</label>
                               <span class="text-gray-500 fw-bold fs-6">{{ getDetailTargetOrderStatus(detail.payload.target_order_statuses_id) }}</span>
                           </div>
                           <div class="fv-row mb-3 target-admin-row" v-if="detail.payload.target_admin_id && detail.payload.target_admin_id > 0 && Object.keys(detail.targetAdmin).length">
                               <label class="fs-5 fw-bold me-2 mb-0">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.detail.cols.targetAdmin.title') }}:</label>
                               <span class="text-gray-500 fw-bold fs-6">{{ detail.targetAdmin.full_name }}</span>
                               <div class="text-gray-700 fw-bold fs-8">
                                    <span class="svg-icon svg-icon-1x svg-icon-warning">
                                        <inline-svg src="/media/icons/duotune/general/gen007.svg" />
                                    </span>
                                   {{ sprintf($t('pages.ecommerce.orderManagement.changeUsageMulti.detail.cols.targetAdmin.description'), [detail.targetAdmin.full_name]) }}
                               </div>
                           </div>
                           <div v-if="detail.process_detail" class="mt-7">
                               <h3 class="mb-5">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.detail.processDetail') }}</h3>
                               <div class="fv-row mb-3" v-if="detail.process_detail.order_count">
                                   <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.detail.cols.order.count') }}:</label>
                                   <span class="text-gray-500 fw-bold fs-6">{{ detail.process_detail.order_count }}</span>
                               </div>
                               <div class="fv-row mb-3" v-if="detail.process_detail.order_item_count">
                                   <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.detail.cols.order.itemCount') }}:</label>
                                   <span class="text-gray-500 fw-bold fs-6">{{ detail.process_detail.order_item_count }}</span>
                               </div>
                           </div>
                           <div v-for="(packageItem, packageItemIndex) in detail.payload.package_items" :key="packageItemIndex" :class="packageItemIndex == 0 ? 'mt-10' : 'mt-7'">
                               <h4 class="mb-5">{{ sprintf($t('pages.ecommerce.orderManagement.changeUsageMulti.detail.packageItemTitlePattern'),[(packageItemIndex + 1)]) }}</h4>
                               <div class="fv-row mb-3" v-if="detail.product.packageItemsObj && detail.product.packageItemsObj[packageItem.product_id]">
                                   <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.detail.cols.product') }}:</label>
                                   <span class="text-gray-500 fw-bold fs-6">{{ detail.product.packageItemsObj[packageItem.product_id].product.translate.title + ' (' + detail.product.packageItemsObj[packageItem.product_id].product.group.name + ')' }}</span>
                               </div>
                               <div class="fv-row mb-3">
                                   <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.detail.cols.usageMinDate') }}:</label>
                                   <span class="text-gray-500 fw-bold fs-6">{{ packageItem.usage_min_date ? $moment(packageItem.usage_min_date).format("DD.MM.YYYY - HH:mm:ss") : "-"}}</span>
                               </div>
                               <div class="fv-row mb-3">
                                   <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.detail.cols.usageMaxDate') }}:</label>
                                   <span class="text-gray-500 fw-bold fs-6">{{ packageItem.usage_max_date ? $moment(packageItem.usage_max_date).format("DD.MM.YYYY - HH:mm:ss") : "-"}}</span>
                               </div>
                               <div class="fv-row mb-3">
                                   <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.orderManagement.changeUsageMulti.detail.cols.justWithoutUsage') }}:</label>
                                   <span class="text-gray-500 fw-bold fs-6">{{ $t('common.' + (packageItem.just_without_usage ? 'yes': 'no')) }}</span>
                               </div>
                           </div>
                       </template>
                    </div>
                </div>

                <div class="modal-footer flex-center">
                    <button class="btn btn-lg btn-primary" type="button" v-on:click="hideModal($refs.detailModal)">{{ $t("btn.close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";
export default {
    name: "index",
    components: {
        CustomTable
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t('pages.ecommerce.orderManagement.changeUsageMulti.cols.admin'),
                    key: "admin.full_name"
                },
                {
                    name: this.$t('pages.ecommerce.orderManagement.changeUsageMulti.cols.payload.order'),
                    scopedSlots: {customRender: "order"}
                },
                {
                    name: this.$t('pages.ecommerce.orderManagement.changeUsageMulti.cols.payload.product'),
                    key: "payload.product_id"
                },
                {
                    name: this.$t('pages.ecommerce.orderManagement.changeUsageMulti.cols.payload.justWithoutPackage'),
                    scopedSlots: {customRender: "justWithoutPackage"}
                },
                {
                    name: this.$t('pages.ecommerce.orderManagement.changeUsageMulti.cols.payload.startProcessingNow'),
                    scopedSlots: {customRender: "startProcessingNow"}
                },
                {
                    name: this.$t('pages.ecommerce.orderManagement.changeUsageMulti.cols.state'),
                    scopedSlots: {customRender: "state"}
                },
                {
                    name: this.$t('common.createdAt'),
                    scopedSlots: {customRender: "created_at"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                }
            ],
            status: [
                {
                    code: 'pending',
                    badge: 'badge-primary'
                },
                {
                    code: 'processing',
                    badge: 'badge-info'
                },
                {
                    code: 'completed',
                    badge: 'badge-success'
                },
                {
                    code: 'cancelled',
                    badge: 'badge-warning'
                },
                {
                    code: 'error',
                    badge: 'badge-danger'
                }
            ],
            detail: {
                payload: {},
                product: {},
                targetAdmin: {},
            }
        }
    },
    computed: {
        table() {
            return this.$store.state.ecommerce.order.item.changeUsageMulti.table;
        },
        orderStatus() {
            return this.$store.state.ecommerce.order.status.table.data;
        },
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("menu.orderChangeUsageMulti"), [this.$t("menu.orderManagement")]);

        this.$store.dispatch('ecommerce/order/item/changeUsageMulti/get', {
            page: {},
            filterData: Object.assign(this.$root.getFilterWithUrl({}), {
                sort: 'created_at:desc',
            })
        });

        this.$store.dispatch('ecommerce/order/status/get', {
            page: { pageSize: 9999 }
        });
    },
    methods: {
        refreshTable(){
            this.$store.dispatch("ecommerce/order/item/changeUsageMulti/refresh");
        },
        handleClickAction(name) {
            switch (name) {
                case "new":
                    this.$router.push({
                        path: "/ecommerce/order-management/change-usage-multi/save"
                    });
                    break;

                case "refresh":
                    this.refreshTable();
                    break;

                default:
                    break;
            }
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("ecommerce/order/item/changeUsageMulti/get", {
                page: pagination,
                filterData: Object.assign({
                    sort: 'created_at:desc'
                }, filterData)
            });
        },
        showDetail(record){
            this.detail.payload = record.payload;
            this.detail.process_detail = record.process_detail ?? null;
            let packageItemIDs = record.payload.package_items && record.payload.package_items.length ? record.payload.package_items.map(item => item.product_id) : [];

            this.detail.product = {};
            this.axios.get(this.endpoints['ecommerce_product'] + '/' + record.payload.product_id).then((response) => {
                let data = response.data.data;
                data.translate = this.resolveDatum(data.translations, this.$root.defaultLanguage.id, 'language_id');

                if(data.package_items && data.package_items.length){
                    let packageItems = {};

                    data.package_items.forEach((packageItem) => {
                        if(packageItem.product.type_id == 2 && packageItemIDs.includes(packageItem.product_package_id)){
                            packageItem.product.translate = this.resolveDatum(packageItem.product.translations, this.$root.defaultLanguage.id, 'language_id')
                            packageItems[packageItem.product_package_id] = packageItem;
                        }
                    });
                    data.packageItemsObj = packageItems;
                }

                this.detail.product = data;
            });

            this.detail.targetAdmin = {};
            if(record.payload.target_admin_id && record.payload.target_admin_id > 0){
                this.axios.get(this.endpoints['admin'] + '/' + record.payload.target_admin_id).then((response) => {
                    let data = response.data.data;
                    this.detail.targetAdmin = data;
                });
            }

            setTimeout(() => {
                this.showModal(this.$refs.detailModal);
            }, 500)
        },
        deleteRecord(id) {
            this.$store.dispatch("ecommerce/order/item/changeUsageMulti/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        getDetailTargetOrderStatus(targetOrderStatus){
            let data = [];

            targetOrderStatus.forEach((targetOrderState) => {
                let state = this.resolveDatum(this.orderStatus, targetOrderState, 'id', null);
                if(state){
                    data.push(state.name);
                }
            })

            return data.length ? data.join(', ') : "-";
        }
    }
}
</script>

<style>
.target-admin-row .svg-icon {
    margin-left: -3px;
    margin-right: -2px;
}
</style>